import * as React from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"

import Layout from "../components/layout"
import SEO from "../components/seo"

import { useQuery, useMutation, gql } from "@apollo/react-hooks"

const IndexPage = props => {
  const APOLLO_QUERY = gql`
    {
      characters(page: 2, filter: { name: "rick" }) {
        info {
          count
        }
        results {
          name
        }
      }
      location(id: 1) {
        id
      }
      episodesByIds(ids: [1, 2]) {
        id
      }
    }
  `

  const { loading, error, data } = useQuery(APOLLO_QUERY)

  if (loading) {
    return <h1>Loading....</h1>
  }

  console.log(data)
 if(data){
  return (
    <Layout>
      <SEO title="Home" />
      {data.characters.results.map(result => {
        return <h2>{result.name}</h2>
      })}
    </Layout>
  )
 }

}

export default IndexPage
